<template>
  <base-section
    id="k-d-a-specialty"
    class="background-primary-gradient"
    space="36"
  >
    <v-container>
      <v-row
        class="mx-n6"
      >
        <v-col
          v-for="(item, i) in m_arrSpecialty"
          :key="i"
          cols="12"
          sm="4"
        >
          <k-d-a-specialty-card
            align="center"
            color="white"
            text-color="white"
            :icon="item.icon"
            :str-title="item.strTitle"
            :str-description="item.strDescription"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPartner',

    components: {
      KDASpecialtyCard: () => import('@/components/KDASpecialtyCard'),
    },

    data: () => ({
      m_arrSpecialty: [
        {
          icon: 'icon-seo.png',
          strTitle: 'Web Dev',
          strDescription: 'Creating website layout/user interfaces by using standard HTML/CSS practices.',
        },
        {
          icon: 'icon-app.png',
          strTitle: 'Mobile App',
          strDescription: 'Mobile Developers involves the process, life, and creation of the software aimed at helping users',
        },
        {
          icon: 'icon-game-development.png',
          strTitle: 'Game Dev',
          strDescription: 'Game developers work on teams to plan, design, and produce video games for computers, mobile devices, or game consoles.',
        },
      ],
    }),
  }
</script>

<style lang="sass">
  .background-primary-gradient
    background: radial-gradient(closest-side, $accent2, $accent1)
</style>
